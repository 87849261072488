* {
  box-sizing: border-box;
}

*,
:focus {
  outline: none;
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  padding: 0;
}
