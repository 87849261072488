$break-small: 320px;
$break-medium: 768px;
$break-large: 1024px;
$break-mobile-large: 568px;
$break-custom-346: 346px;
$break-custom-360: 360px;
$break-custom-450: 450px;
$break-custom-690: 690px;
$break-custom-1280: 1280px;
// colors
$cw: #fff;
$cb: #000;
$csc: #931735;
$cscDark: #7b132c;
$cbgBlue: #1d7bce;
$cbgBlueDark: #1c5d99;
$cbgBlueDarker: #194772;
$cbgBlueLight: #d2e5f5;
$cbgGray: #f4f4f6;
$cbgModal: #f8f9fa;
$cbGrayLight: #e9e9e9;
$cbGrayLighter: #f3f3f3;
$cLink: #1d7bce;
$cLinkHover: #1c5d99;
$cBlue: #1d7bce;
$cPrimary: #3f4043;
$cGray: #b0b1b5;
$cLine: #3f8fd5;
$cLineSC: #931735;
$cLineGray: #dadadb;
$cGreen: #41a854;
$cOk: #5dd142;
$cKo: #ef5353;
$cGeenHover: #388c47;
$cBlueHover: #1866ab;
$cUnred: #00ff18;
$cProgress: #528ae8;
$cNotStarted: #eca324;
$cOverdue: #931735;
$cTableTh: #f6f6f6;
$cError: #c60000;
$cRowHover: #f0f7fc;
$cTableBorder: #e2e2e2;
// homepage / tiles / menu
$cHpGrey: #e9e9e9;
$cHpDarkGrey: #b4b4b4;
$cHpDarkestGrey: #3f4043;
$cHpHover: #000;
$cHpHoverSubmenu: #252525;
$cHpHoverText: #fff;
// spaces, padding
$space: 20px;
$padding: 5px 10px;
// font size
$font-size: 16px;
$ff: "Segoe UI", "Segoe", tahoma, helvetica, arial, sans-serif;
// $open-sans: 'OpenSansRegular', tahoma, helvetica, arial, sans-serif;
// $ff: $open-sans;
$ffMono: "Courier New", courier, monospace !important;
$newDashboards: true !default;
