.d-table { display: table !important; }
.d-table-row { display: table-row !important; }
.d-flex { display: flex !important; }
.d-block { display: block; }
.d-inline-block { display: inline-block !important; }
.d-none { display: none !important; }

.flex-center-x { justify-content: center !important; }
.flex-center-y { align-items: center !important; }
.flex-center-self-y { align-self: center !important; }
.flex-between-x { justify-content: space-between !important; }
.flex-wrap { flex-wrap: wrap !important; }
.flex-row-wrap { flex-flow: row wrap !important; }
.flex-start { justify-content: flex-start !important; }
.flex-end { justify-content: flex-end !important; }
.flex-col { flex-direction: column !important; }
.flex-even { justify-content: space-evenly !important; }

.flex-center-xy {
  justify-content: center !important;
  align-items: center !important;
}

.flex-grow-1 {
  flex: 1 0 0 !important;
  -ms-flex: 1;
}

.flex-shrink-0 { flex-shrink: 0 !important; }

.mh-20 { min-height: 20px; }
.mh-40 { min-height: 40px; }
.mh-60 { min-height: 60px; }
.mh-80 { min-height: 80px; }
.mh-100 { min-height: 100px; }

.h-100 { height: 100%; }
.vh-100 { height: 100vh; }

.w-30 { width: 30%; }
.w-50 { width: 50%; }
.w-80 { width: 80%; }
.w-100 { width: 100%; }

.hidden { visibility: hidden; }
.no-scroll { overflow: hidden !important; }
.overflow-hidden { overflow: hidden !important; }
.relative { position: relative; }
.absolute { position: absolute; }
.v-center { vertical-align: middle !important; }

// camel cased naming deprecated.
.shift-d1 { position: relative; top: 1px; }
.shift-d2 { position: relative; top: 2px; }
.shift-l2 { position: relative; left: -2px; }
.shift-l5 { position: relative; left: -5px; }

// new naming
.shift-r2 {
  position: relative;
  left: 2px;
}

.shift-r1 {
  position: relative;
  left: 1px;
}

.shift-l1 {
  position: relative;
  left: -1px;
}

.float-left { float: left !important; }
.float-right { float: right !important; }
.float-none { float: none !important; }

.h-100 { height: 100%; }
.vh-100 { height: 100vh; }
