/* --- Margin --- */
// -all
.m0 { margin: 0 !important; }
// -bottom
.mb2 { margin-bottom: 2px !important; }
.mb4 { margin-bottom: 4px !important; }
.mb8 { margin-bottom: 8px !important; }
.mb10 { margin-bottom: 10px !important; }
.mb12 { margin-bottom: 12px !important; }
.mb15 { margin-bottom: 15px !important; }
.mb16 { margin-bottom: 16px !important; }
.mb20 { margin-bottom: 20px !important; }
.mb24 { margin-bottom: 24px !important; }
.mb25 { margin-bottom: 25px !important; }
.mb30 { margin-bottom: 30px !important; }
.mb40 { margin-bottom: 40px !important; }
// -top
.mt15 { margin-top: 15px !important; }
.mt16 { margin-top: 16px !important; }
.mt20 { margin-top: 20px !important; }
.mt25 { margin-top: 25px !important; }
.mt30 { margin-top: 30px !important; }
.mt40 { margin-top: 40px !important; }
// -left
.ml-auto { margin-left: auto !important; }
.ml3 { margin-left: 3px !important; }
.ml4 { margin-left: 4px !important; }
.ml5 { margin-left: 5px !important; }
.ml6 { margin-left: 6px !important; }
.ml8 { margin-left: 8px !important; }
.ml12 { margin-left: 12px !important; }
.ml16 { margin-left: 16px !important; }
// -right
.mr2 { margin-right: 2px !important; }
.mr4 { margin-right: 4px !important; }
.mr5 { margin-right: 5px !important; }
.mr8 { margin-right: 8px !important; }
.mr12 { margin-right: 12px !important; }
.mr16 { margin-right: 16px !important; }

/* --- Padding --- */
// -all
.p0 { padding: 0 !important; }
.p2 { padding: 2px !important; }
.p20 { padding: 20px !important; }
// -top
.pt8 { padding-top: 8px !important; }
.pt16 { padding-top: 16px !important; }
.pt24 { padding-top: 24px !important; }
// -bottom
.pb0 { padding-bottom: 0 !important; }
.pb2 { padding-bottom: 2px !important; }
.pb10 { padding-bottom: 10px !important; }
.pb16 { padding-bottom: 16px !important; }
.pb24 { padding-bottom: 24px !important; }
// -left
.pl8 { padding-left: 8px !important; }
.pl12 { padding-left: 12px !important; }
.pl10 { padding-left: 10px !important; }
.pl16 { padding-left: 16px !important; }
.pl20 { padding-left: 20px !important; }
// -right
.pr16 { padding-right: 16px !important; }
