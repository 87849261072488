@import '../../../../../styles/variables/button.scss';
/* ==========================================================================
   Modals
   ========================================================================== */

//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

// Kill the scroll on the body
.modal-open {
  /*
  position: fixed;
  overflow-y: scroll;
  width: 100vw;
  height: 100vh;  */
}

// Container that the modal scrolls within
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  -webkit-overflow-scrolling: touch;
  background-color: rgba(0, 0, 0, 0.5);

  * {
    box-sizing: border-box;
  }

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    //transform: translate(-50%,-50%);
  }

  &.in .modal-dialog {
  }

  &.fgp {
    .modal-dialog {
      top: 20vh;
    }
  }
}

.modal-open .modal {
  /*
  overflow-x: hidden;
  overflow-y: auto;
  */
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  margin: auto;

  /*
  position: absolute;
  top: 50%;
  left: 50%;
  transition: translate 0.2s ease-out;
  transform: translate(-50%, -60%)
  */
}

.ei-top {
  .modal-dialog {
    margin: 0;
  }
}

// Actual modal
.modal-content {
  @extend %modal-content;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  background-color: $cb;
  // Fade for backdrop
  &.fade {
    opacity: 0;
  }

  &.in {
    opacity: 0.5;
  }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  @extend %modal-header;
}
// Close icon
.modal-header .close {
  margin-top: -2px;
}

// Title text within header
.modal-title {
  margin: 0;

  span {
    font-size: inherit;
    display: inline !important;
    width: auto;
  }
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  @extend %modal-body;
}

// Footer (for actions)
.modal-footer {
  @extend %modal-footer;
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal-sm {
  width: 460px;
}

.modal-md {
  width: 500px;
}

.modal-lg {
  width: 600px;
}

.modal-xl {
  width: 900px;
}

.modal-xs {
  width: 400px;
}

.modal-header {
  padding: 10px $space;
  background: $cbgModal;

  .close-btn {
    @include btnReset();
    float: right;
    width: 20px;
    height: 20px;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  .close {
    float: right;
    font-size: 21px;
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    border: 0;
    opacity: 0.7;
    margin-top: 0;
    width: 20px;
    height: 20px;
    overflow: hidden;
    line-height: 20;
    background: url("../../graphics/svg/ico-x.svg") no-repeat center center;
    background-size: 9px 9px;

    &:hover {
      opacity: 1;
    }
  }
}

.modal-footer {
  padding: 10px $space;
  background-color: $cbgModal;

  &.two-buttons {
    .btn {
      float: left;
      width: 200px;
    }

    .btn + .btn {
      float: right;
    }
  }

  &.three-buttons {
    .btn {
      float: left;
      width: 150px;
    }

    .btn-default,
    .btn-grey {
      float: right;
    }

    .btn-default {
      margin-left: 10px;
    }
  }
}

.modal-body {
  background-color: $cw;
  padding: $space;

  * {
    font-size: 13px;
  }
}

.modal-sc-content {
  height: 100%;
  position: relative;
  padding-bottom: 51px;

  .modal-body {
    overflow: auto;
    //max-height: 70vh;
    max-height: calc(100vh - 150px);
    height: 90%;
    height: calc(100% - 40px);

    &.min-height {
      min-height: calc(100vh - 150px);
    }

    .ps-content {
      padding-right: 10px;
    }

    .jstree {
      overflow: hidden;
    }
  }

  .modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .modal-header {
    .modal-title {
      font-size: 16px;
    }
  }

  &.user-settings {
  }

  &.acc-filter {
    input[type="text"] {
      width: 100%;
      margin-bottom: $space;
    }

    ul {
      @extend .rounded;
      @extend .border-gray;

      display: block;
      list-style-type: none;
      padding: 0;
      margin: 0;
      max-height: 100%;
      overflow: auto;

      li {
        display: block;
        border-bottom: 1px solid $cbGrayLight;
        padding: $padding;
        background-color: $cw;
        cursor: pointer;

        > a {
          display: block;
          color: $cPrimary;
          text-decoration: none;
          cursor: pointer;
          transition: color 0.2s ease-out;

          &:hover {
            color: $cLinkHover;
            text-decoration: none;
          }
        }

        &.selected {
          a {
            background:
              url("../../graphics/svg/ico-edit-field-tick-blue.svg")
              no-repeat right center $cw;
            background-size: 14px 10px;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .modal-body {
    }

    .modal-footer {
      .btn + .btn {
        background-image: url("../../graphics/svg/ico-switch-white.svg");
        background-repeat: no-repeat;
        background-position: right 16px center;
        background-size: 20px 14px;
        text-align: left;
        padding-left: $space;
      }
    }
  }
}
