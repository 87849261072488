@import 'variables';

@mixin scSpinner {
  font-size: 10px;
  position: absolute;
  text-indent: -9999em;
  top: 50%;
  left: 50%;
  margin: -35px 0 0 -20px;
  border-top: 5px solid rgba(0, 0, 0, 0.1);
  border-right: 5px solid rgba(0, 0, 0, 0.1);
  border-bottom: 5px solid rgba(0, 0, 0, 0.1);
  border-left: 5px solid $csc;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: rotateAnimation 1.1s infinite linear;
  animation: rotateAnimation 1.1s infinite linear;
  transform-origin: 50% 50%;
  border-radius: 50%;
  width: 50px;
  height: 50px;

  &::after {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
}

@-webkit-keyframes rotateAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotateAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
