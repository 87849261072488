
$base-color: $grey20;
$shimmer-color: lighten($base-color, 2%);
$animation-duration: 1.6s;
$offset: 50;

@mixin skeleton-shimmer-bg {
  background-image: linear-gradient(90deg, $base-color 0px, $shimmer-color 40px, $base-color 80px);
  background-size: 600px;
}

@mixin skeleton-shimmer-bg-full-width {
  background-image: linear-gradient(90deg, $base-color 0, $shimmer-color 5%, $base-color 10%);
  background-size: 200%;
}

@keyframes skeleton-shimmer-bg-frames {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 244px;
  }
}

@keyframes skeleton-shimmer-bg-frames-full-width {
  0% {
    background-position: 215%;
  }

  40%,
  100% {
    background-position: 50%;
  }
}

@keyframes skeleton-shimmer-bg-frames-offset {
  0% {
      background-position: -100px + $offset;
  }

  40%, 100% {
      background-position: 140px + $offset;
  }
}
