@import 'color';
@import 'type';
@import 'transition';

$arrow-size: 7px;
$arrow-offset: calc(#{-$arrow-size} + 1px);

.sc-tooltip {
  background-color: $grey80;
  border-radius: 4px;
  @include body();
  color: white;
  position: relative;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity .16s ease-out;
  max-width: 180px;
  word-break: break-all;

  .tooltip-inner {
    padding: 4px 8px;
  }

  .tooltip-arrow {
    position: absolute;
    content: '';
    border-color: transparent;
    border-style: solid;
  }

  &.bs-tooltip-end {
    margin-left: $arrow-size;

    .tooltip-arrow {
      top: calc(#{$arrow-size} + 3px);
      left: $arrow-offset;
      border-width: $arrow-size $arrow-size $arrow-size 0;
      border-right-color: $grey80;
    }
  }

  &.bs-tooltip-start {
    margin-right: $arrow-size;

    .tooltip-arrow {
      top: calc(#{$arrow-size} + 3px);
      right: $arrow-offset;
      border-width: $arrow-size 0 $arrow-size $arrow-size;
      border-left-color: $grey80;
    }
  }

  &.bs-tooltip-top {
    margin-top: $arrow-size;

    .tooltip-arrow {
      bottom: $arrow-offset;
      border-width: $arrow-size $arrow-size 0 $arrow-size;
      border-top-color: $grey80;
    }
  }

  &.bs-tooltip-bottom {
    margin-top: $arrow-size;

    .tooltip-arrow {
      top: $arrow-offset;
      left: auto;
      border-width: 0 $arrow-size $arrow-size $arrow-size;
      border-bottom-color: $grey80;
    }
  }
}

.sc-tooltip.show {
  opacity: 1;
}
