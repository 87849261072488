$black: #000;
$black-04: rgba(0, 0, 0, 0.4);
$black-01: rgba(0, 0, 0, 0.1);
$black-003: rgba(0, 0, 0, 0.03);

$grey80: #55524e;
$grey60: #908e8b;
$grey40: #c5c3c0;
$grey30: #dad8d5;
$grey20: #e8e6e4;
$grey10: #f3f2f1;
$grey05: #f9f8f8;

$white: #fff;
$white-08: rgba(255, 255, 255, 0.8);
$white-06: rgba(255, 255, 255, 0.6);
$white-04: rgba(255, 255, 255, 0.4);
$white-02: rgba(255, 255, 255, 0.2);

$primary: #931735;
$primary-dark: #671025;

$green: #37c99e;
$green-dark: #098762;

$yellow: #fbcc54;

$orange: #f39b34;

$red: #e11f4e;

$blue: #0094fe;
$blue-02: #006FBF;


$icon-color: $grey60;
$icon-hover-color: $grey80;
