/* ==========================================================================
   NEW UI
   ========================================================================== */

.ei-file {
  background: url('../../graphics/svg/ico-document.svg') no-repeat 0 6px !important;
  background-size: 14px 18px;

  &.lib_text {
    background: url('../../graphics/svg/ico-document.svg') no-repeat 0 6px !important;
    background-size: 14px 18px;
  }

  &.pdf,
  &.lib_acrobat {
    background: url('../../graphics/svg/ico-doc-pdf.svg') no-repeat 0 6px !important;
    background-size: 14px 18px;
  }

  &.xls,
  &.xlsx,
  &.csv,
  &.ods,
  &.lib_excel {
    background: url('../../graphics/svg/ico-doc-excel.svg') no-repeat 0 6px !important;
    background-size: 14px 18px;
  }

  &.doc,
  &.docx,
  &.odf,
  &.lib_word {
    background: url('../../graphics/svg/ico-doc-word.svg') no-repeat 0 6px !important;
    background-size: 14px 18px;
  }

  &.zip,
  &.rar {
    background: url('../../graphics/svg/ico-doc-zip.svg') no-repeat 0 6px !important;
    background-size: 14px 18px;
  }

  &.txt,
  &.drf {
    background: url('../../graphics/svg/ico-doc-word.svg') no-repeat 0 6px !important;
    background-size: 14px 18px;
  }

  &.ppt,
  &.pptx,
  &.ppv,
  &.lib_powerpoint {
    background: url('../../graphics/svg/ico-doc-ppt.svg') no-repeat 0 6px !important;
    background-size: 14px 18px;
  }

  &.jpg,
  &.png,
  &.gif,
  &.bmp,
  &.tif,
  &.jpeg,
  &.lib_picture {
    background: url('../../graphics/svg/ico-doc-img.svg') no-repeat 0 6px !important;
    background-size: 14px 18px;
  }
}

/*
.lib_download {background-position: 3px 3px; cursor: pointer; min-width: 20px;}
.lib_no_download {background-position: 3px 3px; cursor: default; min-width: 20px;}
*/

.ei-library {
  @extend .clearfix;

  background: $cw;
  border: 1px solid $cLineGray;
  border-radius: 4px;

  ul {
    @extend .clearfix;

    display: block;
    list-style-type: none;
    list-style-image: none;
    padding: 0;
    margin: 0;

    li {
      @extend .clearfix;

      list-style-type: none;
      display: block;
      padding: 0;

      > .level {
        a,
        strong {
          transition: background-color 0.2s ease-in;
          display: block;
          padding: 5px 5px 5px 30px;
          cursor: pointer;
          color: $cPrimary;
          //border-bottom: 1px solid $cLineGray;

          &:hover {
            color: $cLinkHover;
            //background-color: $cRowHover !important;
          }
        }

        a {
          @extend .ei-file;
        }

        > strong {
          background: url('../../graphics/svg/ico-folder-closed.svg') no-repeat 0 6px !important;
          background-size: 16px 13px;
        }
      }

      ul {
        overflow: hidden;
        height: 0;
        transition: opacity 0.2s ease-in;
        opacity: 0;
      }


      &.expanded {
        > .level {
          > strong {
            background: url('../../graphics/svg/ico-folder-open.svg') no-repeat 0 6px !important;
            background-size: 18px 14px;
          }
        }

        > ul {
          height: auto;
          opacity: 1;
        }
      }
    }
  }

  > ul {
    padding: 10px 0;

    > li {
      > .level {
        a,
        strong {
          border-bottom: none;
        }
      }

      > ul {
        > li {
          &:last-child {
            > .level {
              a,
              strong {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }

  // levels
  ul {
    .level {
      padding-left: 10px;
    }

    ul {
      .level {
        padding-left: 40px;
      }

      ul {
        .level {
          padding-left: 70px;
        }

        ul {
          .level {
            padding-left: 100px;
          }

          ul {
            .level {
              padding-left: 130px;
            }
          }
        }
      }
    }
  }
}


/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #aaa;
  opacity: 0.3;
}

.ui-draggable-handle {
  -ms-touch-action: none;
  touch-action: none;
}

.ui-resizable {
  position: relative;
}

.ui-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
  -ms-touch-action: none;
  touch-action: none;
}

.ui-resizable-disabled .ui-resizable-handle,
.ui-resizable-autohide .ui-resizable-handle {
  display: none;
}

.ui-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0;
}

.ui-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0;
}

.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%;
}

.ui-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%;
}

.ui-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px;
  background: url("../../graphics/img/ui-icon-gripsmall-diagonal-se.png") no-repeat right bottom;
}

.ui-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px;
}

.ui-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px;
}

.ui-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px;
}

.ui-selectable {
  -ms-touch-action: none;
  touch-action: none;
}

/*
.heading-first {border-top-left-radius: 3px;-webkit-border-top-left-radius: 3px;-moz-border-top-left-radius: 3px;-khtml-border-top-left-radius: 3px;}
.heading-last {border-top-right-radius: 3px;-webkit-border-top-right-radius: 3px;-moz-border-top-right-radius: 3px;-khtml-border-top-right-radius: 3px;}
.scTable tr:last-child td:first-child{border-bottom-left-radius: 3px;-webkit-border-bottom-left-radius: 3px;-moz-border-bottom-left-radius: 3px;-khtml-border-bottom-left-radius: 3px;}
.scTable tr:last-child td:last-child{border-bottom-right-radius: 3px;-webkit-border-bottom-right-radius: 3px;-moz-border-bottom-right-radius: 3px;-khtml-border-bottom-right-radius: 3px;}
*/

tr.selectedPortletResourceLink, tr.selectedOneClickResourceLink {
  & > td {
    background-color: #e4e4e4 !important;
  }
}

.padding {
  padding: 10px !important;

  table {
    width: 100%;
  }
}


/*** dashboard */

.multi-portlet-container {
  overflow: visible;
}

.dashboard-menu-container {
  height: 45px;
  width: 100%;

  ul {
    list-style-type: none;
    list-style-image: none;
    padding: 0;
    margin: 0;

    li {
      margin: 0 !important;
      font-size: 14px;
      border: none;
      color: $cLink;
      font-family: $ff;
      padding: 4px 12px 0 12px;
      margin: 0 4px 0 0;

      &.dashboard-menu-current {
        color: $cPrimary;
      }
    }
  }
}

.ddmenu {
  @extend %ddmenu;
}

.dash-conf-demo {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: top;
  width: 10px;
  height: 18px;
  background: url("../../graphics/svg/ico-menu.svg") no-repeat center center;
  cursor: pointer;
}

.questionnaire,
.vulenerability,
.scan {
  display: inline-block;
  margin: 0 auto 20px;
  width: 90px;
  height: 90px;
  border-radius: 45px;
  text-align: center;

  &.compliant {
    background-color: $cOk;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &.notcompliant {
    background-color: $cKo;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.questionnaire {
  background-image: url("../../graphics/svg/pci-questionnaire.svg");
  background-size: 38px 46px;
}

.vulenerability {
  background-image: url("../../graphics/svg/pci-lightning.svg");
  background-size: 24px 51px;
}

.scan {
  &.compliant {
    background-image: url("../../graphics/svg/pci-tick.svg");
    background-size: 52px 37px;
  }

  &.notcompliant {
    background-image: url("../../graphics/svg/pci-x.svg");
    background-size: 38px 38px;
  }
}

/// tree
.ei-tree {
  display: block;

  li {
    list-style-type: none;
    display: block;

    ul {
      display: block;
    }
  }
}

.add-group {
  margin-right: 5px;
}

.delete-group {
  margin-left: 5px;
}

/** icons */

.ei-ico {
  @extend %ei-ico;
}

.ei-move-left {
  @extend %ei-ico;

  height: 18px;
  opacity: 1;
  background: url("../../graphics/svg/ico-circlearrow-left.svg") no-repeat 0 0;
  background-size: 18px 38px;

  &:hover {
    background-position: 0 -20px;
  }
}

.ei-move-right {
  @extend %ei-ico;

  opacity: 1;
  height: 18px;
  background: url("../../graphics/svg/ico-circlearrow-right.svg") no-repeat 0 0;
  background-size: 18px 38px;

  &:hover {
    background-position: 0 -20px;
  }
}


.ace_editor {
  height: 150px;
  border: 2px solid #ccc;
}

a.ei-yes,
a.ei-no,
a.ei-x {
  display: inline-block;
  width: 20px;
  height: 14px;
  margin: auto;
  background: url("../../graphics/svg/ico-green-tick.svg") no-repeat center center;
  background-size: 13px 12px;

  img {
    display: none;
  }
}

a.ei-yes:focus-visible,
a.ei-no:focus-visible,
a.ei-x:focus-visible {
  outline: 1px solid $cbgBlueDark;
  outline-offset: 0;
}

a.ei-no {
  background: url("../../graphics/svg/ico-red-x.svg") no-repeat center center;
  background-size: 12px 12px;
}

a.ei-x {
  background: url("../../graphics/svg/ico-grey-x.svg") no-repeat center center;
  background-size: 16px 16px;
}

.ei-delete {
  @extend %ei-ico;

  background: url("../../graphics/svg/ico-delete.svg") no-repeat center center;
  background-size: 18px 18px;

  &.inactive {
    background: url("../../graphics/svg/ico-delete-inactive.svg") no-repeat center center;
    background-size: 18px 18px;
  }
}

.ei-add-task {
  @extend %ei-ico;

  background: url("../../graphics/svg/ico-task-add.svg") no-repeat center center;
  background-size: 20px 18px;
}

.ei-generate-pdf {
  @extend %ei-ico;

  background: url("../../graphics/svg/ico-generatereport.svg") no-repeat center center !important;
  background-size: 15px 18px;
}

.ei-edit {
  @extend %ei-ico;

  background: url("../../graphics/svg/ico-edit.svg") no-repeat left center;
  background-size: 20px 14px;
}

.ei-add-file {
  @extend %ei-ico;

  background: url("../../graphics/svg/ico-add.svg") no-repeat center center !important;
  background-size: 15px 15px;
}

.ei-calendar {
  @extend %ei-ico;

  background: url("../../graphics/svg/ico-calendar.svg") no-repeat center center !important;
  background-size: 19px 17px;
}

.ei-generate-report {
  @extend %ei-ico;

  background: url("../../graphics/svg/ico-generatereport.svg") no-repeat center center !important;
  background-size: 15px 18px;
}

.ei-link {
  @extend %ei-ico;

  background: url("../../graphics/svg/ico-openlink.svg") no-repeat center center !important;
  background-size: 18px 18px;
}

.ei-upload-results {
  @extend %ei-ico;

  background: url("../../graphics/svg/ico-upload.svg") no-repeat center center !important;
  background-size: 17px 17px;
}

.ei-download-results {
  @extend %ei-ico;

  background: url("../../graphics/svg/ico-download.svg") no-repeat center center !important;
  background-size: 18px 18px;
}

.ei-scan {
  @extend %ei-ico;

  background: url("../../graphics/svg/ico-scan.svg") no-repeat center center !important;
  background-size: 17px 13px;
}

.ei-move-down,
.ei-move-up {
  @extend %ei-ico;

  width: 10px;

  span {
    height: 20px !important;
    width: 10px !important;
  }
}

.ei-move-down {
  background: url("../../graphics/svg/arrow-down2.svg") no-repeat center center !important;
  background-size: 8px 5px;
}

.ei-move-up {
  background: url("../../graphics/svg/arrow-up2.svg") no-repeat center center !important;
  background-size: 8px 5px;
}

.ei-vulnerabilities {
  @extend %ei-ico;

  background: url("../../graphics/svg/ico-vulnerabilities.svg") no-repeat center center !important;
  background-size: 16px 17px;
}

.ei-expand-up,
.ei-expand-down {
  @extend %ei-ico;

  opacity: 1;
}

.ei-expand-up {
  background: url("../../graphics/svg/expand-up.svg") no-repeat center center;
  background-size: 18px 18px;
}

.ei-expand-down {
  background: url("../../graphics/svg/expand-down.svg") no-repeat center center;
  background-size: 18px 18px;
}


.assignees {
  .ei-help {
    float: none;
    display: inline-block;
  }

  .headings {
    font-family: $ff;

    td {
      padding: 0 0 5px 0;
    }

    select {
      width: 100%;
      min-width: 200px;
    }
  }

  .selects {
    select {
      height: 220px;
      min-width: 150px;
      width: 100%;
      background: none;
      padding-right: 0;

      &#unassigned,
      &#assigned {
        width: 320px;
      }
    }

    td {
      &:last-child {
        padding: 0 !important;
      }
    }

    td + td {
      padding: 0 10px !important;
    }

    a {
      margin-bottom: 5px;
    }
  }

  .search {
    vertical-align: middle;
    white-space: nowrap;

    input {
      float: right;
      width: 250px;
      width: calc(100% - 70px);
    }

    td {
      padding: 10px 0 0 0;
      vertical-align: middle;

      &:first-child {
        line-height: 29px;
      }
    }
  }
}

.targets {
  .headings {
    font-family: $ff;

    td {
      padding: 0 0 5px 0;
    }
  }

  .selects {
    select {
      height: 220px;
      min-width: 150px;
      background: none;
      padding-right: 0;

      &#unassigned {
        width: 320px;
      }
    }

    td {
      &:last-child {
        padding: 0 !important;
      }
    }

    td + td {
      padding: 0 10px !important;
    }

    a {
      margin-bottom: 5px;
    }
  }

  .ei-move-right,
  .ei-move-left {
    min-height: 18px;
    max-height: 18px;
    border-color: transparent;
    border: none;
    overflow: hidden;
    line-height: 99;

    &:disabled {
      background-position: 0 0;
      opacity: 0.5;
    }
  }

  .ei-move-right {
    margin-top: 5px;
  }
}

.ei-links {
  padding: 20px 0;
  margin: 0;
  line-height: 23px;

  > li {
    padding: 0 0 10px 0;

    .ei-icon > span {
      width: 20px;
    }

    a {
      &:first-child {
        margin-right: 10px;
      }
    }

    ul {
      padding: 0 0 0 20px;
      margin: 0;

      li {
        padding: 0 0 5px 0;

        strong {
          display: inline-block;
          min-width: 90px;
          margin-right: 10px;
          vertical-align: middle;
        }
      }
    }
  }
}

.ui-tooltip {
  display: block;
  opacity: 1;
  position: absolute;
  z-index: 99999;
}

div.hover,
.ui-tooltip {
  @extend %modal-content;

  position: absolute;
  display: none;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;

  p {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

a.hover {
  text-decoration: none;
  font-weight: bold;
}

.ei-download {
  text-align: center;
  padding: 20px 0;
  min-width: 200px;

  > div {
    text-align: center;
  }

  .ei-icon {
    margin: auto;
    display: inline-block;

    span {
      width: 20px;
      margin-bottom: 5px;
    }
  }
}

// jobs
.ei-targets {
  width: 100%;

  strong {
    display: block;
    clear: both;
    font-family: $ff; //"clear_sans_lightregular", Arial, sans-serif;
    padding: 0 0 5px 0;
    font-size: 13px !important;
  }

  > tbody > tr {
    .ei-help {
      float: none;
      display: inline-block;
      clear: both;
    }

    > td:first-child {
      width: 400px;

      > div {
        font-size: 0 !important;
        padding-bottom: 3px;
      }

      select,
      input {
        display: inline-block;
        margin-bottom: 10px;
        vertical-align: top;
        font-size: 13px !important;
      }

      select {
        width: 300px !important;
        margin-right: 4px;

        &#ipAddressSslTargetSsl {
          width: 90px !important;
        }
      }

      input {
        width: 300px !important;
        margin-right: 4px;

        &[type="button"] {
          width: 40px !important;
        }

        &#ipAddressSslTarget {
          width: 133px !important;
        }

        &#ipAddressSslTargetPort {
          width: 70px !important;
        }
      }

      input + strong {
        padding-top: 10px;
      }
    }

    > td {
      border: 1px solid $cLine;
      border-radius: 3px;
      border-right: none;
      padding: 10px 20px 10px 10px;
    }

    > td + td {
      border: 1px solid $cLine;
      border-left: none;
      padding-left: 20px;

      select {
        display: block;
        width: 100% !important;
      }
    }
  }
}

.ui-helper-clearfix {
  @extend %cf;
}

.add-evidence {
  @extend %cf;

  height: 25px;
  margin-top: -5px;
  line-height: 25px;

  button,
  input,
  > span {
    float: right;
  }

  input {
    height: 24px;
    min-height: 24px;
    margin: 0 10px 0 5px;
  }

  button {
    &.ui-button {
      height: 24px;
      line-height: 14px;
    }

    &.ui-button-icon-only {
      float: left;

      &:hover {
        .ui-icon {
          opacity: 1;
        }
      }
    }
  }
}

.contextMenuPlugin {
  opacity: 1;
  filter: alpha(opacity=255);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=255)";
  -webkit-user-select: none;
  display: none;
  font-family: $ff;
  font-size: 11px;
  position: absolute;
  left: 100px;
  top: 100px;
  min-width: 100px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #f7f3f7;
  border: 2px solid #f7f7f7;
  outline: 1px solid #949694;
  list-style: none;
  z-index: 5010;
  overflow: hidden;

  & > li {
    border: 1px solid #f7f3f7;
    margin: 0 0 1px 0;
    padding: 1px;
    background-repeat: no-repeat;
    list-style-type: none;
    list-style: none;

    & > a {
      position: relative;
      display: block;
      padding: 2px 3px 2px 28px;
      text-decoration: none;
      margin: 1px;

      img {
        position: absolute;
        left: 3px;
        margin-top: -2px;
        width: 16px;
        height: 16px;
      }
    }

    &.item {
      &:hover {
        border: 1px solid #fffbff;
        outline: 1px solid #b5d3ff;
        background: linear-gradient(
            to bottom,
            rgba(239, 239, 255, 0.5) 0%,
            rgba(223, 223, 255, 0.5) 100%
        );
        cursor: default;
      }
    }

    &.divider {
      border-top: 1px solid #e7e3e7;
      border-bottom: 1px solid #fff;
      height: 0;
      padding: 0;
      margin: 5px 0 5px 27px;
    }
  }

  & > .header {
    background: rgb(90, 90, 90);
    background: linear-gradient(
        to bottom,
        rgba(90, 90, 90, 1) 0%,
        rgba(20, 20, 20, 1) 100%
    );
    position: relative;
    cursor: default;
    padding: 3px 3px 3px 3px;
    color: #fff;
  }

  & > .gutterLine {
    position: absolute;
    border-left: 1px solid #e7e3e7;
    border-right: 1px solid #fff;
    width: 0;
    top: 0;
    bottom: 0;
    left: 26px;
    z-index: 0;
  }
}

.contextMenuMask {
  background-color: #000;
  opacity: 0.01;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5005;
}

.scColourPicker {
  border-spacing: 1px;
  border-collapse: separate;
  background-color: #fff;
  border: 1px solid #ccc;

  td {
    border: 3px solid #fff;
    height: 25px;
    width: 25px;
    cursor: pointer;
  }
}

.scColourPickerSel {
  border-color: #f00 !important;
}

.scGrey {
  color: #808080;
}

.scgantt_project {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scgantt_task {
  padding-left: 20px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scgantt_tasknp {
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// fn-gantt
.fn-gantt {
  * {
    box-sizing: content-box;
  }

  .scgantt_high {
    background-color: #963d3b;
  }

  .scgantt_medium {
    background-color: #c27535;
  }

  .scgantt_low {
    background-color: #799244;
  }

  .scgantt_projectbar {
    background-color: #3c6494;
  }
}

.fn-gantt-hint {
  padding: 8px;
  border-style: solid;
  border-width: 2px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  td {
    padding: 1px 4px 0 4px;
  }
}

.scChart {
  .sc-updated-label {
    right: 10px;
  }
}

.no-data {
  width: 100% !important;
  height: 20px !important;
  line-height: 20px;
  text-align: center;
  left: 0;
  top: 45%;
  top: calc(50% - 10px);
  position: absolute;
  color: $cGray;

  .sc-updated-label {
    position: relative;
    text-align: left;
  }
}

.ConfTitle {
  font-weight: bold;
  font-size: 13px;
  padding: 4px 0 4px 10px;
  cursor: pointer;
  padding-bottom: 10px;
}

/*
.ConfTitle.open {
    background-image: url(images/south.png);
    background-position: 0px 8px;
    background-repeat: no-repeat;
}

.ConfTitle.closed {
    background-image: url(images/east.png);
    background-position: 0px 8px;
    background-repeat: no-repeat;
} */


#hierarchy {
  margin-right: 40px;

  + .ei-help,
  + .ei-help-active {
    position: absolute;
    top: 0;
    left: 310px;
  }
}

// tree
.tree-wrap {
  min-width: 20px !important;
}

// inline forms

.default-ul {
  @extend %default-ul;
}

.inline-select {
  @extend %default-ul;

  background: $cw;
  border-radius: 0;
  //width: 100%;
  max-width: 600px;
  height: auto;
  max-height: 300px;
  overflow: auto;
  display: inline-block;
  padding: 5px 2px;
  margin: 0;
  white-space: normal;

  li {
    padding: 5px 10px;

    label {
      width: 100%;
      margin-right: 0;
      font-size: 13px;
      line-height: 15px;
    }
  }

  li:focus-visible {
    outline: 2px solid $primary;
    outline-offset: 0;
  }

  &.disabled {
    opacity: 1;

    input[type="checkbox"]:checked + label span:first-child,
    .ei-check input[type="checkbox"]:checked + span {
      background: url("../../graphics/svg/checkbox-selected-disabled.svg") no-repeat center center;
      background-size: 18px 18px;
    }

    input[type="radio"]:checked + label span:first-child,
    .ei-radio input[type="radio"]:checked + span {
      background: url("../../graphics/svg/radio-on-disabled.svg") no-repeat center center;
      background-size: 18px 18px;
    }
  }
}

.inline-reference {
  @extend %default-ul;

  li {
    padding: 0 0 5px 0;
    display: block;
    float: left;
    clear: both;

    &.scFormReference {
      color: $cLink;
      cursor: pointer;

      &:hover {
        color: $cLinkHover;
        text-decoration: underline;
      }
    }
  }
}

/*********************** radios menu */
.inline-radio-horizontal,
.inline-radio-vertical {
  @extend %default-ul;

  white-space: normal;
  display: inline-block;

  li {
    vertical-align: middle;
    line-height: 20px;
    min-height: 20px;
    background: url("../../graphics/svg/radio-off.svg") no-repeat left top;
    background-size: 18px 18px;

    &.checked {
      background: url("../../graphics/svg/radio-on.svg") no-repeat left top;
      background-size: 18px 18px;
    }
  }

  &.disabled {
    opacity: 1;

    li {
      &.checked {
        background: url("../../graphics/svg/radio-on-disabled.svg") no-repeat left top;
        background-size: 18px 18px;
      }
    }
  }
}

.inline-radio-horizontal {
  li {
    display: inline-block;
    padding: 0 10px 0 25px;
  }
}

.inline-radio-vertical {
  @extend %default-ul;

  li {
    padding: 0 0 0 25px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/****************** icons */

.opacity-hover {
  @extend %opacity-hover;
}

.ei-evidence li span,
.evidence-ico,
.scTblTree .ui-icon,
.grid-ico {
  vertical-align: middle;
  opacity: 0.48;
  transition: opacity 0.2s ease-out;
  display: inline-block;
  width: 25px;
  height: 20px;
  position: relative;
  top: -1px;
  background: url("../../graphics/svg/buttons/btn-download.svg") no-repeat left center !important;
  background-size: 18px 18px;

  &.lib_text {
    background: url("../../graphics/svg/ico-document.svg") no-repeat left center !important;
    background-size: 14px 18px;
  }

  &.lib_upload {
    background: url("../../graphics/svg/buttons/btn-upload.svg") no-repeat left center !important;
    background-size: 18px 18px;
  }

  &.lib_acrobat {
    background: url("../../graphics/svg/ico-doc-pdf.svg") no-repeat left center !important;
    background-size: 14px 18px;
  }

  &.lib_excel {
    background: url("../../graphics/svg/ico-doc-excel.svg") no-repeat left center !important;
    background-size: 14px 18px;
  }

  &.lib_word {
    background: url("../../graphics/svg/ico-doc-word.svg") no-repeat left center !important;
    background-size: 14px 18px;
  }

  &.lib_powerpoint {
    background: url("../../graphics/svg/ico-doc-ppt.svg") no-repeat left center !important;
    background-size: 14px 18px;
  }

  &.lib_picture {
    background: url("../../graphics/svg/ico-doc-img.svg") no-repeat left center !important;
    background-size: 14px 18px;
  }

  &.lib_url,
  &.lib_uri {
    background: url("../../graphics/svg/ico-openlink.svg") no-repeat left center !important;
    background-size: 18px 18px;
  }

  &.lib_folder {
    background: url("../../graphics/svg/ico-folder-closed.svg") no-repeat left center !important;
    background-size: 16px 13px;
  }

  &.lib_folderopen {
    background: url("../../graphics/svg/ico-folder-open.svg") no-repeat left center !important;
    background-size: 18px 14px;
  }

  &.lib_application {
    background: url("../../graphics/svg/ico-import-form.svg") no-repeat left center !important;
    background-size: 18px 10px;
  }

  &.lib_form {
    //background: url('../../graphics/svg/ico-import-form.svg') no-repeat  left center !important;
    //background-size: 18px 10px;

    background: url("../../graphics/svg/buttons/btn-form.svg") no-repeat -2px center !important;
    background-size: 18px 18px;
  }

  &.lib_pen {
    background: url("../../graphics/svg/ico-edit.svg") no-repeat left center !important;
    background-size: 20px 14px;
  }

  &.lib_standard {
    //background: url('../../graphics/svg/ico-folder-closed.svg') no-repeat  left center !important;
    //background-size: 16px 13px;
    background: url("../../graphics/svg/buttons/btn-initiative.svg") no-repeat left center !important;
    background-size: 18px 18px;
  }
}


.sc-usergrid {
  display: inline-block;
}

// color pickers
#divColourPicker {
  * {
    box-sizing: border-box;
  }
}

.colpick_field,
.colpick_hex_field {
  vertical-align: bottom;
  line-height: 20px;

  input {
    float: left;
    top: 2px;
    width: 50px !important;
    font-size: 12px !important;
    line-height: 16px !important;
    height: 18px !important;
    min-height: 18px !important;
    border: none !important;

    &:hover,
    &:focus {
      outline: 0;
      border: none !important;
    }

    &::-ms-clear {
      display: none;
    }
  }
}

.colpick_field_letter {
  width: 20px;
  left: 0;
  line-height: 20px;
  top: 0;
}
