@import 'color';
@import 'type';

/* Temporary styles for links within an AG grid inside a portlet. This is until the
grid component is refactored to render an ag-link component for any links. Colours
can then be set using the ag-link/link components */

// ag-cell required for specificity
div.ag-cell {
  &.sc-grid-link, .sc-grid-link {
    @include bodyLink();
    @include linkBlue02();
    > span:not(.ag-icon) {
      @include bodyLink();
      @include linkBlue02();
    }
  }
  .sc-grid-link + .sc-grid-link {
    margin-top: 8px
  }
}
