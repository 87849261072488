@mixin btnReset {
  -webkit-appearance: none;
  background: none;
  background-color: none;
  padding: 0;
  border: none;
  height: auto;

  &:hover {
    background-color: transparent;
  }
}
