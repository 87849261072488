/* ==========================================================================
   Typography
   ========================================================================== */

/*
@font-face {
    font-family: 'clear_sansregular';
    src: url('../../graphics/font/ClearSans-Regular-webfont.eot');
    src: url('../../graphics/font/ClearSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../graphics/font/ClearSans-Regular-webfont.woff') format('woff'),
         url('../../graphics/font/ClearSans-Regular-webfont.ttf') format('truetype'),
         url('../../graphics/font/ClearSans-Regular-webfont.svg#clear_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'clear_sansbold';
    src: url('../../graphics/font/ClearSans-Bold-webfont.eot');
    src: url('../../graphics/font/ClearSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../graphics/font/ClearSans-Bold-webfont.woff') format('woff'),
         url('../../graphics/font/ClearSans-Bold-webfont.ttf') format('truetype'),
         url('../../graphics/font/ClearSans-Bold-webfont.svg#clear_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'clear_sans_lightregular';
    src: url('../../graphics/font/ClearSans-Light-webfont.eot');
    src: url('../../graphics/font/ClearSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../graphics/font/ClearSans-Light-webfont.woff') format('woff'),
         url('../../graphics/font/ClearSans-Light-webfont.ttf') format('truetype'),
         url('../../graphics/font/ClearSans-Light-webfont.svg#clear_sans_lightregular') format('svg');
    font-weight: normal;
    font-style: normal;

}  */

a {
  color: $cLink;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $cLinkHover;
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $ff;
  font-weight: 400;
}

h2 {
  margin: 5px 0 0;
}

b,
strong {
  font-weight: normal;
  font-family: $ff;
}

.bold {
  font-family: $ff;
}

.text-content,
.md-preview {
  em {
    font-style: italic;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  b,
  strong {
    font-family: $ff;
    font-weight: bold;
  }

  p {
    display: block;
    margin: 0 0 10px 0;
  }

  ul {
    padding: 0 0 5px 10px;
    list-style-type: none;

    li {
      list-style-type: none;
      padding: 0 0 0 20px;
      position: relative;
      margin-top: 3px;
      background:
        url("../../graphics/img/commentary_bullet_point.png")
        no-repeat 0 7px;

      ol,
      ul {
        padding-top: 10px;
      }
    }
  }

  ol {
    padding: 0 20px 0 20px;
    list-style-type: decimal;

    li {
      list-style-type: decimal;
      padding: 0 0 10px 0;

      ol,
      ul {
        padding-top: 10px;
      }
    }
  }

  a {
    color: $csc;

    &:hover {
      text-decoration: none;
      color: $cscDark;
    }
  }

  p {
    &:empty {
      display: none;
    }
  }

  table {
    width: auto !important;
    min-width: 120px;
    clear: both;
    margin-bottom: 20px;
    border-collapse: collapse;

    tr {
    }

    td,
    th {
      vertical-align: top;
      width: auto;
      border: 1px solid $cLineGray;
      padding: 5px 10px 5px 10px;

      h2,
      h3,
      h4 {
        margin: 10px 0;
      }
    }

    th {
      font-family: $ff;
    }
  }

  ul + h2,
  ul + h3,
  ol + h2,
  ol + h3 {
    margin-top: 0;
  }

  table + h2 {
    margin-top: 0;
  }

  h2 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h3,
  h4 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h2 + h3 {
    margin-top: 0;
  }

  blockquote {
    padding: 10px 20px;
    margin: 10px 0 20px;
    padding: 0 1em;
    color: inherit;
    border-left: 0.25em solid rgba(0, 0, 0, 0.11);
    border-left: 5px solid #eee;
  }
}
