/* deprecated */.hand:hover {
  cursor: pointer;
}

.cursor-default:hover { cursor: default !important; }
.cursor-hand:hover { cursor: pointer !important; }

.no-transition { transition: none !important; }
.pointer-auto {
  pointer-events: auto;
}
