/* LAYOUT */
html {
  // overflow-y: scroll;
}

* {
  outline: none;
  box-sizing: border-box;
  //font-family: $ff;
}

body,
html {
  margin: 0;
  padding: 0;
  background-color: $cw;
  min-height: 100vh;
  font-size: 13px;
  color: $cPrimary;
  width: 100%;
  z-index: 1;
  //overflow: hidden;

  font-family: $ff;
  font-weight: normal;

  &.white {
    background: $cw;
  }
}

section,
main,
footer,
header {
  display: block;
}

body,
.page {
  transition: opacity 0.4s ease-out;
  opacity: 1;
  z-index: 99;
  min-width: 650px;

  &.fade {
    > #ei-loading {
      opacity: 1;
      z-index: 999;
    }
  }
}

img {
  display: block;
  border-style: none;
  max-width: 100%;
  height: auto;
}

app-root,
router-outlet,
app-dynamic-content,
app-menu {
  @extend %cf;

  display: block;
}

.clearfix,
.cf {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

.rounded {
  border-radius: 3px;
}

.border-gray {
  border: 1px solid $cbGrayLight;
}

.nod {
  display: none;
}

.nod2 {
  display: none !important;
}

.c8cb {
  clear: both;
}

html {
  //overflow-y: scroll;
}

.scrollbar {
  overflow: auto;
}

$header_height: 50px;
// $header_height: 90px; // larger to prevent scrollbar when bottom scrollbar is visible

$tools_height: 42px;
$bread_height: 28px;
$submenu_height: 30px;

.page {
  position: relative;
  transition: padding-top 0.2s ease-out;

  &.login,
  &.reporting {
    padding-top: 0 !important;

    #ei-loading {
      display: none;
    }
  }

  .no-loader {
    opacity: 1;

    #ei-loading {
      display: none;
    }
  }

  &.reporting {
    min-height: 100%;

    .c8-dashboards-contents {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.ei-wrap {
  @extend %cf;

  max-width: 1280px;
  margin: auto;

  @include respond-to(custom1280) {
    padding-left: 20px;
    padding-right: 40px;
  }
}

.format-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  tr {
    td {
      padding: 5px 20px 5px 0;

      * {
        vertical-align: top;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &:first-child {
      td {
        padding-top: 0;
      }
    }

    &:last-child {
      td {
        padding-bottom: 0;
      }
    }
  }

  &.new {
    tr {
      td {
        vertical-align: top;
        line-height: 30px;

        .ei-help {
          position: relative;
          top: 5px;
        }
      }

      &:first-child {
        td {
          padding-top: 5px;
        }
      }
    }
  }
}

#ei-loading {
  transition: opacity 0.1s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  opacity: 0;
  padding-top: 45vh;
  padding-top: calc(50vh - 25px);
  background: rgba(255, 255, 255, 0.6);

  &.loading-contents {
    z-index: 999;
    opacity: 1;
  }

  .loader {
    margin: 0 auto;
    font-size: 10px;
    position: absolute;
    top: 50%;
    //top: calc(50% - 35px);
    left: 45%;
    left: calc(50% - 35px);
    text-indent: -9999em;
    border-top: 5px solid rgba(0, 0, 0, 0.1);
    border-right: 5px solid rgba(0, 0, 0, 0.1);
    border-bottom: 5px solid rgba(0, 0, 0, 0.1);
    border-left: 5px solid $csc;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }

  .loader,
  .loader::after {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
}

%spinner {
  font-size: 10px;
  position: absolute;
  text-indent: -9999em;
  top: 50%;
  left: 50%;
  margin: -35px 0 0 -20px;
  border-top: 5px solid rgba(0, 0, 0, 0.1);
  border-right: 5px solid rgba(0, 0, 0, 0.1);
  border-bottom: 5px solid rgba(0, 0, 0, 0.1);
  border-left: 5px solid $csc;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  transform-origin: 50% 50%;
  border-radius: 50%;
  width: 50px;
  height: 50px;

  &::after {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
}

.load-save-animation {
  @extend %spinner;
}

.sc-portlet-loading {
  background: none !important;
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);

  .ei-loader {
    @extend %spinner;
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* HOLDING PAGE */
// body{border-top: 2px solid #ffcc00;} // ???

.ei-demo {
  padding: 100px;
  text-align: center;

  ul {
    list-style-type: none;
    text-align: center;
    display: block;
    font-size: 20px;

    li {
      padding: 5px 0;

      a {
        color: $cPrimary;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

/** MAIN CONTENT */
.ei-content {
  @extend %cf;

  clear: both;
  position: relative;
  z-index: 60;
  padding: 20px 20px;
  min-height: 40vh;
}

.tempMenu {
  background: #eee;
  padding: 20px;
  display: none;

  .list-table {
    width: 100%;

    th {
      text-align: left;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 4px 8px;

      &:last-child {
        border-right: 0;
      }
    }

    td {
      border-right: 1px solid #ccc;
      padding: 4px 8px;

      &:last-child {
        border-right: 0;
      }
    }
  }
}

#loading-bar-spinner {
  top: 17px !important;
  left: 16px !important;
}

#divContent,
.divContent {
  background: $cbGrayLight;
}
