@import 'color';
@import 'icon-color';

.white { color: white !important; }
.black { color: black !important; }
.bg-white { background-color: white !important; }
.bg-grey10 { background-color: $grey10 !important; }
.bg-grey20 { background-color: $grey20 !important; }
.bg-carmine { background-color: #b9333d !important; }
.bg-denim { background-color: #1d7bce !important; }
.bg-black-04 { background-color: $black-04 !important; }

.text-primary { color: $primary; }
.icon-color { color: $icon-color; }
