@mixin loginControlBase {
  border-radius: 3px;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  margin: 0;
  background-color: $cw;
  border: none;
  font-size: 18px;
  width: 100%;
}
