@import 'type';

.sc-h1, .sc-h2, .sc-h3, .sc-h4, .sc-h5 {
  margin: 0;
  padding: 0;
}

.sc-h1 { @include h1(); }
.sc-h2 { @include h2(); }
.sc-h3 { @include h3(); }
.sc-h4 { @include h4(); }
.sc-subheading { @include subheading(); }
.sc-lead { @include lead(); }
.sc-body { @include body(); }
.sc-body-link { @include lead(); }
.sc-caption { @include caption(); }
.sc-label { @include lead(); }

.text-left { text-align: left !important; }
.text-center { text-align: center !important; }
.bold { font-weight: bold !important; }
.truncate-base { @include truncateBase; }

/** Font Size */
.fs16 { font-size: 16px !important; }

.lh0 { line-height: 0; }
.lh17 { line-height: 17px; }
