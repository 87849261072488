@import 'skeleton';

.sc-skeleton__icon {
  height: 18px;
  width: 18px;
  border-radius: 4px;
  @include skeleton-shimmer-bg();
  animation: skeleton-shimmer-bg-frames $animation-duration infinite linear;
}

.sc-skeleton__icon--sm {
  height: 16px;
  width: 16px;
}

.sc-skeleton__title {
  width: 120px;
  height: 18px;
  border-radius: 4px;
  @include skeleton-shimmer-bg();
  animation: skeleton-shimmer-bg-frames $animation-duration infinite linear;
}

.sc-skeleton__tag {
  width: 56px;
  height: 22px;
  border-radius: 25px;
  @include skeleton-shimmer-bg();
  animation: skeleton-shimmer-bg-frames $animation-duration infinite linear;
}
