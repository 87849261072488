/* ==========================================================================
   Helpers
   ========================================================================== */

%cf {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

%btn {
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 0;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
  text-align: center;
  position: relative;
  border: $cbgBlue;
  background: $cbgBlue;
  color: $cw;
  height: 30px;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition:
    background-color 0.2s ease-out,
    color 0.2s ease-out,
    border 0.2s ease-out;

  &:hover {
    border: $cbgBlueDark;
    background: $cbgBlueDark;
    color: $cw;
  }

  &:active,
  &.active {
    background-image: inherit;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: $cbgBlueDark;
  }

  &:disabled {
    background-color: $cbGrayLight !important;
    color: $cGray !important;
    cursor: no-drop;
    border: 1px solid $cbGrayLight;
  }

  &.small {
    padding: 0.42em 0.5em;
  }
}

%btn-grey {
  @extend %btn;

  border: 1px solid $cLineGray;
  background-color: transparent;
  color: $cPrimary;

  &:hover {
    border: 1px solid $cbgBlueDark;
    background-color: transparent;
    color: $cPrimary;
  }
}

%btn-red {
  @extend %btn;

  border: 1px solid $csc;
  background-color: $csc;
  color: $cw;

  &:hover {
    border: 1px solid $cscDark;
    background-color: $cscDark;
    color: $cw;
  }
}

%default-ul {
  list-style-type: none;
  list-style-image: none;
  padding: 0;
  margin: 0;
  display: block;
  clear: both;
  position: relative;

  li {
    display: block;
    padding: 0 0 0 0;
  }
}

%opacity-hover {
  opacity: 0.48;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }
}

%ei-ico {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  opacity: 0.48;
  transition: opacity 0.2s ease-out;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  img {
    display: none;
  }
}

%modal-content {
  position: relative;
  background-color: $cw;
  border: 1px solid $cLineGray; //old browsers fallback (ie8 etc)
  border: 1px solid $cLineGray;
  border-radius: 4px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  // Remove focus outline from opened modal
  outline: 0;

  * {
    font-size: 13px;
  }

  &.ui-resizable {
  }
}

%modal-header {
  @extend %cf;

  padding: 10px $space;
  cursor: move;
  border-bottom: 1px solid $cLineGray;

  .load-save-animation {
    position: absolute;
    top: 10px;
    left: auto;
    right: 55px;
    margin: 0;
    width: 20px;
    height: 20px;
    border-width: 3px;

    &::after {
      border-radius: 50%;
      width: 20px;
      height: 20px;
    }
  }
}

%modal-body {
  position: relative;
  padding: $space;

  img {
    max-width: 100%;
    height: auto;
  }
}

%modal-footer {
  @extend %cf;

  padding: $space;
  text-align: right; // right align buttons
  border-top: 1px solid $cLineGray;

  // Properly space out buttons
  .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
  }
  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
  // and override it for block buttons as well
  .btn-block + .btn-block {
    margin-left: 0;
  }
}

%ddmenu {
  z-index: 100;
  transition: max-height 0.3s ease-in, opacity 0.2s ease-in;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  top: 30px;
  right: 0;
  padding: 7px 20px;
  margin: 0;
  list-style-type: none;
  list-style-image: none;
  border-radius: 4px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  background: $cw;

  li {
    display: block;
    white-space: nowrap;
    padding: 5px 0;
    margin: 0;
    line-height: 20px;
    color: $cPrimary;

    &:hover {
      color: $cLinkHover;
    }
  }
}
