/* You can add global styles to this file, and also import other style files */
@import '../../../styles/reset/reset.scss';

@import "../../core/src/css/_variables";
@import "../../core/src/css/partials/_mixins";
@import "../../core/src/css/partials/_helpers";
@import "../../core/src/css/partials/_layout";
@import "../../core/src/css/partials/_form";
@import "../../core/src/css/partials/_typography";
@import "../../core/src/css/partials/_modals"; //
@import '../../core/src/css/partials/new_ui';
@import "./css/login";

html, body {
    font-family: $ff;
}

h1, .login-app__h1 {
    font-size: 18px;
    line-height: 22px;
    color: $cPrimary;
    display: block;
    text-align: left;
    padding: 55px 0 25px 0;
    font-weight: normal;
    margin: 0;
}

.popover {
    // white-space: nowrap;
    max-width: none;
    padding: 10px;
}

.password-reset__info {
    // must use nested selectors here
    // as we display dynamic html
    // (so can't add classes to it yet)
    max-width: 480px;
    font-size: 13px;

    ul {
        padding: 0 0 5px 10px;
        list-style-type: none;
        list-style-image: none;
        font-size: 13px;
        margin: 0;
    }

    li {
        list-style-type: none;
        list-style-image: none;
        padding: 0 0 0 20px;
        position: relative;
        margin-top: 3px;
        background: url('./assets/graphics/img/commentary_bullet_point.png') no-repeat 0 7px;
        font-size: 13px;
    }
}

// Move to Utils
.ei-wrap { text-align: center; }

/* Utils always last */
@import '../../../styles/utils/utils.scss';
