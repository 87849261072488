/* ==========================================================================
	 Form
	 ========================================================================== */
@import 'color';
@import 'type';

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px $cw inset;
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px $cw inset;
  -webkit-text-fill-color: #333;
}

* {
  outline: none;

  &:focus {
    outline: none;
  }
}

a[ng-click] {
  cursor: pointer;
}

.btn,
.btn-red,
input[type="button"],
button {
  @extend %btn;
}

.btn-grey,
input[type="button"].btn-grey,
button.btn-grey {
  @extend %btn-grey;
}

.btn-red {
  @extend %btn-red;
}

.ui-helper-hidden-accessible {
  display: none;
}

.ei-help,
.ei-icon.ei-help,
.ei-help-active,
.ei-icon.ei-help-active {
  display: block;
  float: left;
  width: 18px;
  height: 18px;
  background: url('../../graphics/svg/ico-info.svg') no-repeat center center;
  background-size: 18px 18px;
  cursor: help;
  margin-left: 5px;
  transition: opacity 0.2s ease-out;
  opacity: 0.48;

  &:hover {
    opacity: 1;
  }

  &.nm {
    margin-left: 0;
  }

  &.inline {
    display: inline-block;
    float: none;
  }

  img,
  span {
    display: none;
  }
}

.ei-help-active,
.ei-icon.ei-help-active {
  opacity: 1;
}

.ei-helptext {
  padding: 0 0 0 30px;
  position: relative;

  &::before {
    width: 18px;
    height: 18px;
    transition: opacity 0.2s ease-out;
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    background: url('../../graphics/svg/ico-info.svg') no-repeat center center;
    background-size: 18px 18px;
    opacity: 0.48;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
}

.read-only {
  color: $cGray !important;
}

//real checkboxes, real radio
input[type="checkbox"]:not(.ag-input-field-input),
input[type="radio"]:not(.ag-input-field-input) {
  display: none !important;
}

input[type="checkbox"] + label,
input[type="radio"] + label,
.ei-check {
  display: inline-block;
  margin: 0;
  padding: 0;
  cursor: pointer;
  vertical-align: middle;
}

input[type="radio"] + label {
  margin-right: 20px;
}

input[type="radio"] + label span:first-child,
input[type="checkbox"] + label span:first-child,
.ei-check span,
.ei-radio span {
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 10px;
}

input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  & + label {
    pointer-events: none;
    color: $cGray;

    span:first-child {
    }
  }
}

span.ei-check span {
  margin-right: 0;
}

input[type="checkbox"] + label span:first-child,
.ei-check input[type="checkbox"] + span {
  background: url('../../graphics/svg/checkbox-off.svg') no-repeat center center;
  background-size: 18px 18px;
  border-radius: 4px;
}

input[type="checkbox"]:checked + label span:first-child,
.ei-check input[type="checkbox"]:checked + span {
  background: url('../../graphics/svg/checkbox-on.svg') no-repeat center center;
  background-size: 18px 18px;
}

input[type="radio"] + label span:first-child,
.ei-radio input[type="radio"] + span {
  background: url('../../graphics/svg/radio-off.svg') no-repeat center center;
  background-size: 18px 18px;
}

input[type="radio"]:checked + label span:first-child,
.ei-radio input[type="radio"]:checked + span {
  background: url('../../graphics/svg/radio-on.svg') no-repeat center center;
  background-size: 18px 18px;
}

input[type="button"] {
  &#remove {
    margin-left: 10px;
  }
}

input,
select,
textarea,
button {
  font-family: inherit;
}

input,
select,
textarea {
  @extend .rounded;
  @extend .border-gray;
  //font-family:$ff;
  padding: $padding;
  font-size: 13px;
  min-height: 30px;
  min-width: 50px;
  transition: border-color 0.2s ease;

  &:focus {
    outline: none;
    border-color: $cBlue;
  }

  &.c8fw {
    width: 100%;
  }
}

select {
  padding: 5px 30px 5px 10px;
  -webkit-appearance: none;
  appearance: none;
  min-width: 70px;
  background: #fff url("../../graphics/svg/ico-chevron-menu.svg") calc(100% - 10px)  50% / 9px 6px no-repeat;
}

select[multiple],
select[size] {
  background: #fff;
  padding-right: 0;
}

select[size="1"] {
  padding-right: 30px;
  background: #fff url("../../graphics/svg/ico-chevron-menu.svg") calc(100% - 10px)  50% / 9px 6px no-repeat;
}

select::-ms-expand {
  display: none;
}

input.ng-invalid.ng-dirty,
textarea.ng-invalid.ng-dirty {
  border: solid 2px $cError !important;
}

.invalid-input {
  border-color: $cError !important;
}

form.ng-submitted {
  input.ng-invalid,
  textarea.ng-invalid {
    border: solid 2px $cError !important;
  }
}

.curp {
	cursor: pointer;
}

.copied {
	display: block;
	font-size: 14px;
	color: $cGreen;
}

td {
  &.entitySubTabPane {
    div {
      &.table {
        td {
          input[type="button"] {
            @include subheading();

            height: 24px;
            min-height: 24px;
            min-width: 50px;
            border-radius: 4px;
            padding: 0 8px;
            cursor: pointer;
            color: $white;
            background: $primary;

            &:hover {
              background: $primary-dark;
            }

            &:disabled {
              background: $grey10;
              color: $grey40 !important;
              cursor: no-drop;
              border: 1px solid $grey30;
            }

            &:focus-visible {
              outline: 2px solid $primary;
              outline-offset: 2px;
            }
          }
          &.btn_secondary {
            input[type="button"] {
              @include subheading();

              border: 1px solid $primary;
              color: $primary;
              background: $white;

              &:hover {
                color: $white;
                background: $primary;
              }
              &:disabled {
                border: 1px solid $grey30;
              }
            }
          }
          &.btn_subtle {
            input[type="button"] {
              @include subheading();

              border: 1px solid $grey20;
              color: $grey80;
              background: $white;

              &:hover {
                background: $grey10;
              }
              &:disabled {
                border: 1px solid $grey30;
              }
            }
          }
          &.btn_minimal {
            input[type="button"] {
              @include subheading();

              color: $grey80;
              background: $white;

              &:hover {
                background: $grey10;
              }
            }
          }
        }
      }
    }
  }
}

/* ============================================================
  SWITCH 2 - ROUND FLAT
============================================================ */

/*
.ei-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.ei-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.ei-toggle + label {
  padding: 2px;
  width: 28px;
  height: 18px;
  background-color: #9f9fa1;
  border-radius: 9px;
  transition: background 0.4s;
}
input.ei-toggle + label:before, input.ei-toggle + label:after {
  display: block;
  position: absolute;
  content: "";
}
input.ei-toggle + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  border-radius: 7px;
  transition: background 0.4s;
}
input.ei-toggle + label:after {
  top: 3px;
  left: 3px;
  bottom: 3px;
  width: 12px;
  background-color: $cw;
  border-radius: 50%;
  transition: margin 0.4s, background 0.4s;
}
input.ei-toggle:checked + label {
	background: $cbgBlue;
}
input.ei-toggle:checked + label:after {
  margin-left: 10px;
  background-color: $cw;
}

.switch {
	.ei-toggle + label + span {
		display: inline-block;
		font-size: 10px;
		color: #9f9fa1;
		text-transform: uppercase;
		display: inline-block;
		margin-left: 10px;
	}

	.ei-toggle:checked + label + span {
		color: $cbgBlue;
	}
}
*/
