@import "./color.scss";

$open-sans-reg: 'OpenSansRegular';
$open-sans-semibold: 'OpenSansSemiBold';
$open-sans-bold: 'OpenSansBold';
$open-sans-xbold: 'OpenSansExtraBold';


@mixin h1 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  color: $grey80;
  font-family: $open-sans-semibold;
}

@mixin h2 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  color: $grey80;
  font-family: $open-sans-semibold;
}

@mixin h3 {
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  color: $grey80;
  font-family: $open-sans-bold;
}

@mixin h4 {
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  color: $grey80;
  font-family: $open-sans-bold;
}

@mixin subheading {
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  color: $grey80;
  font-family: $open-sans-semibold;
}

@mixin lead {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: $grey80;
}

@mixin body {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: $grey80;
  font-family: $open-sans-reg;
}

@mixin bodyLink {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: $grey80;
  text-decoration: underline;
  font-family: $open-sans-reg;
}

@mixin caption {
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  color: $grey80;
  font-family: $open-sans-semibold;
}

@mixin label {
  font-size: 9px;
  line-height: 12px;
  font-weight: 800;
  color: $grey80;
}

@mixin truncateBase { /** must add local width */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  display: inline-block;
}

@mixin linkBlue02 {
  color: $blue-02;
  text-decoration: underline;
  &:hover {
    color: $blue-02;
    text-decoration: none;
  }
  &:focus {
    color: $blue-02;
    outline: 2px solid $blue-02;
    border-radius: 1px;
  }
}
