
@mixin respond-to($media) {
  @if $media == phone {
    @media screen and (max-width: $break-medium - 1) { @content; }
  }

  @else if $media == phone-large {
    @media only screen and (min-width: $break-mobile-large) and (max-width: $break-medium - 1) { @content; }
  }

  @if $media == custom346 {
    @media only screen and (max-width: $break-custom-346) { @content; }
  }

  @if $media == custom360 {
    @media only screen and (max-width: $break-custom-360) { @content; }
  }

  @if $media == custom346-690 {
    @media only screen and (min-width: $break-custom-346 + 1) and (max-width: $break-custom-690) { @content; }
  }

  @if $media == custom450 {
    @media only screen and (max-width: $break-custom-450) { @content; }
  }

  @if $media == custom450-768 {
    @media only screen and (min-width: $break-custom-450) and (max-width: $break-medium - 1) { @content; }
  }

  @if $media == custom690 {
    @media only screen and (max-width: $break-custom-690) { @content; }
  }

  @if $media == custom1280 {
    @media only screen and (max-width: $break-custom-1280 - 1) { @content; }
  }

  @if $media == tablet {
    @media only screen and (min-width: $break-medium) and (max-width: $break-large - 1) { @content; }
  }

  @if $media == desktop {
    @media only screen and (min-width: $break-large) { @content; }
  }

  @if $media == desktop-xl {
    @media only screen and (min-width: $break-custom-1280) { @content; }
  }

  @if $media == mobile-devices {
    @media only screen and (max-width: $break-large - 1) { @content; }
  }

  @if $media == small-height {
    @media only screen and (min-height: 700px) { @content; }
  }

  @if $media == xlmedium-height-max {
    @media only screen and (max-height: 900px) { @content; }
  }

  @if $media == medium-height {
    @media only screen and (min-height: 800px) { @content; }
  }

  @if $media == xlmedium-height {
    @media only screen and (min-height: 900px) { @content; }
  }

  @if $media == large-height {
    @media only screen and (min-height: 1000px) { @content; }
  }

  @if $media == xlarge-height {
    @media only screen and (min-height: 1100px) { @content; }
  }

  /*    @else if $media == medium-screens {
            @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) { @content;}
        }
        @else if $media == wide-screens {
            @media only screen and (min-width: $break-large) { @content;}
        } */
}
.clearfix,
.cf {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}