@import "./login-control.scss";

/*-------- New Names ----------*/
.login-app {
  min-height: 100vh;
  z-index: 150;
  height: 100vh;
  width: 100%;
}

.login-app__page {
  background: $cw;
  padding: 0;
  height: 100vh;
  text-align: left;
  min-height: 790px;
}

.login-app__content {
  width: 380px;
}

.login-app__input {
  @include loginControlBase();
  border: 1px solid $cPrimary;
  margin-bottom: 10px;

  &:focus {
    border: 1px solid $cscDark;
  }
}

.login-app__btn {
  @include loginControlBase();
  text-align: center;
  background-color: $csc;
  color: $cw;
  transition: background 0.2s ease-out, color 0.2s ease-out;
  cursor: pointer;

  &:hover {
    background-color: $cscDark;
  }
}

.login-app__info-message {
  font-size: 14px;
  text-align: center;
  border-radius: 3px;
  padding: 10px;
}

.login-app__info-message--error {
  border: 1px solid $cLineSC;
  color: $csc;
}

.login-app__error {
  margin-bottom: 10px;
  color: $cError;
  padding: 10px;
  padding-left: 0;
  text-align: left;
  border-radius: 3px;
  background-color: $cw;

  &.show {
    display: block;
  }
}

.login-app__link {
  color: $csc;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  padding: 15px 0 10px 0;

  &:hover {
    text-decoration: underline;
    color: $cscDark;
  }
}

.login-app__locations {
  display: block;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 9;
}

.login-app__form {
  margin: 0;
  display: block;
}

.login-app__main-logo {
  display: inline-block;
  margin: auto;
  width: auto;
  height: auto;
  max-width: 400px;

  img {
    max-width: 100%;
    height: auto;
    max-height: 100px;
  }
}

.login-app__main-logo--show-default {
  width: 320px;
  height: 60px;
  background: url("../assets/graphics/svg/logo-cropped.svg") no-repeat left
    center transparent;
  background-size: contain;
  background-position: left center;
}

.login-app__branding {}

.login-app__fieldset {
  border: none;
  display: block;
  margin: 0;
  width: 100%;
  text-align: left;
  padding: 10px 0;

  // this class appears to inherit from a
  // dependency: forms.scss so difficult to
  // refactor here.
  .ei-check {
    vertical-align: middle;
    height: 18px;
    line-height: 20px;
    margin: 0 0 20px 0;
    font-size: 16px;

    span {
      float: left;
    }
  }

  input {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px $cw inset;
      -webkit-text-fill-color: #333;
    }

    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 50px $cw inset;
      -webkit-text-fill-color: #333;
    }

    &:hover,
    &:focus {
      outline: 0;
      background-color: $cw;

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 50px $cw inset;
        -webkit-text-fill-color: #333;
      }

      &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 50px $cw inset;
        -webkit-text-fill-color: #333;
      }
    }
  }

  button {
    width: 100%;
    display: block;
    background-color: $csc;
    margin-top: 5px;
    color: $cw;
  }
}

.login-app__footer {
  text-align: center;
  font-size: 12px;
  flex-shrink: 0;
  width: 100%;

  h6 {
    font-size: 12px;
    margin: 0px 0px 13px;
  }
}


.login-app-footer__links {
  display: flex;
  list-style-type: none;
  li {
    a {
      color: $csc;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        color: $cscDark;
      }
    }
    &:before {
      content: "|";
      margin: 0px 5px;
    }
  }
}

.login-app__featured {
  position: relative;
  display: block;
  text-decoration: none;
  float: right;
  height: 100%;
  width: 50%;
  text-align: center;
  background: #f3f3f3 url("../assets/graphics/img/Login-Image.jpg") no-repeat center center;
  background-size: contain;
}

/*-------- Old Names ----------*/
@media only screen and (max-width: 960px) {
    .login-app__featured {
      display: none;
    }

    .login-app__form-area {
      float: none;
      width: 100%;
    }
}
